<div *ngIf="enableVisibilityInfo" class="visibility_info">
    <app-staked-coins></app-staked-coins>
    <app-dev-fund></app-dev-fund>
</div>
<div *ngIf="enableVisibilityInfo" class="visibility_info">
    <app-burning></app-burning>
    <app-calculator></app-calculator>
</div>
<!--<div class="search">
    <app-search (isVisible)="onIsVisible($event)"></app-search>
</div>-->
<div class="blockchain-visualization">
    <div class="chain" [@chainSlide]="recentBlocks.length">
        <div class="block" 
            *ngFor="let block of recentBlocks" 
            [@highlightNewItems]="block['isNew'] || block.height > maxViewedBlockHeight ? 'new': 'old'"
            [ngClass]="{
                'pos-block': block.type == '0',
                'pow-block': block.type == '1',
                'highlight-new': block['isNew'] || block.height > maxViewedBlockHeight
            }">
            <div class="block-header">
                <span>{{ block.height }}</span>
                <span>{{ block.tr_count }} tx</span>
            </div>
            <div class="block-hash">{{block.id | slice:0:8}}...</div>
            <div class="block-type">
                {{block.type == '0' ? 'PoS' : 'PoW'}}
            </div>
        </div>
    </div>
</div>

<div class="info_tab">
    <div class="main_info">
        <div class="height">
            <div class="title">Height</div>
            <div class="value">{{ info?.height | bitNumber }}</div>
        </div>
        <div class="difficulty">
            <div class="title">Difficulty</div>
            <div class="value">
                <div class="pos"><span>PoS:</span>{{ info?.pos_difficulty | bitNumber }}</div>
                <div><span>Now in PoS: ~ {{totalPosCoin}} NiR</span></div>
                <div class="pow"><span>PoW:</span>{{ info?.pow_difficulty | bitNumber }}</div>
            </div>
        </div>
        <div class="coins_emitted">
            <div class="title">Coins Emitted</div>
            <div class="value distribution_coins">Total: {{ info?.total_coins | moneyParse | bitNumber }} NiR</div>
        </div>
        <div class="transactions">
            <div class="title">Transactions</div>
            <div class="value">{{ info?.tx_count + info?.height | bitNumber }}</div>
        </div>
        <div class="hash_rate">
            <div class="title">Hash Rate</div>
            <div class="value">{{
                info?.current_network_hashrate_350 | hashPowerConverter: 'speed'
            }}</div>
        </div>
        <div class="daemon_state">
            <div class="title">Daemon state</div>
            <div class="value">
                <span
                    [ngClass]="{
                        'text-success': info?.daemon_network_state == 2,
                        'text-danger': info?.daemon_network_state != 2
                    }">
                    {{
                        daemon_network_state[info?.daemon_network_state] ||
                            'BUSY'
                    }}
                </span>
            </div>
        </div>
        <div class="dev_fond">
            <div class="title">Nirmata Foundation</div>
            <div class="value">1 089 155 NiR</div>
        </div>
        <div class="network_info network_fee">
            <div>
                <p>Default network fee: </p>
                <span>{{ info?.default_fee / 1000000000000 }} NiR</span>
            </div>
            <div>
                <p>Minimum network fee: </p>
                <span>{{ info?.minimum_fee  / 1000000000000 }} NiR</span>
            </div>
        </div>
    </div>

    <div class="latest_blocks">
        <div class="table_title">Latest Blocks</div>
        <div *ngIf="loader === true" class="wrap_loader">
            <div class="loader"></div>
        </div>
        <table *ngIf="blockCount">
            <thead>
                <tr>
                    <th class="height">Height</th>
                    <th class="timestamp">Timestamp (UTC)</th>
                    <th class="age">Age</th>
                    <th class="size">Size</th>
                    <th class="transaction">Transactions</th>
                    <th class="hash">Hash</th>
                </tr>
            </thead>
            <tbody>
                <tr [@highlightNewItems]="(block?.height > maxViewedBlockHeight) || (block?.height == goToBlock) ? 'new': 'old'"
                    [ngClass]="{
                        pos: block.type == '0',
                        pow: block.type == '1',
                        select: block.select
                    }"
                    *ngFor="let block of selectRangeOfBlockDetails$ | async">
                    <td class="height">
                        <a [routerLink]="['/block', block.id]">{{
                            block?.height
                        }}</a>
                        <span *ngIf="block.type == 0">POS Block</span>
                        <span *ngIf="block.type == 1">POW Block</span>
                    </td>
                    <td class="timestamp">
                        {{
                            block.timestamp * 1000
                                | date: 'yyyy-MM-dd HH:mm:ss':'UTC +0':'en'
                        }}
                    </td>
                    <!--Timestamp-->
                    <td class="age">
                        {{ block.actual_timestamp * 1000 | amTimeAgo }}
                    </td>
                    <!-- age-->
                    <td class="size">{{ block.block_cumulative_size }} bytes</td>
                    <td class="transaction">{{ block.tr_count }}</td>
                    <td class="hash">
                        <span class="cont_hash">
                            <a [routerLink]="['/block', block.id]">{{
                                block.id
                            }}</a>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="pagination" *ngIf="blockCount">
            <div class="left_position">
                <span>Pages:</span>
                <button
                    type="button"
                    class="prev_btn"
                    [disabled]="currentPage <= 1"
                    (click)="prevPage()">
                    <svg x="0px" y="0px" viewBox="0 0 477.175 477.175">
                        <g>
                            <path
                                d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
                c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" />
                        </g>
                    </svg>
                </button>
                <button
                    type="button"
                    class="next_btn"
                    [disabled]="blockCount < setLimit"
                    (click)="nextPage()">
                    <svg x="0px" y="0px" viewBox="0 0 477.175 477.175">
                        <g>
                            <path
                                d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
                c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
                " />
                        </g>
                    </svg>
                </button>
                <input
                    type="text"
                    [value]="currentPage"
                    (input)="currentPage = $event.target.value"
                    (change)="onChange()" />
            </div>
            <div class="right_position">
                <span>Items on page:</span>
                <input
                    type="text"
                    [value]="setLimit"
                    (input)="setLimit = $event.target.value"
                    (change)="onChangeLimit()" />
                <span class="title_go_to_block">Go to block:</span>
                <input
                    class="input_go_to_block"
                    type="text"
                    (input)="setBlock = $event.target.value"
                    (change)="searchBlock()"
                    [ngClass]="{ 'has-error': setBlockValid == false }"
                    placeholder="number" />
            </div>
        </div>
    </div>
</div>
<h1>Transaction pool</h1>
<div class="transaction_pool">

    <!-- IF transaction pool > 0 -->
    <table class="full_table" *ngIf="poolsOn === true && transactionCount > 0">
        <thead>
            <tr>
                <th class="timestamp">Timestamp (UTC)</th>
                <th class="age">Age</th>
                <th class="size">Size</th>
                <th class="fee">Fee</th>
                <th class="hash">Hash</th>
            </tr>
        </thead>
        <tbody>
            <tr [@highlightNewItems]="pools.timestamp > maxViewedPoolTimestamp ? 'new': 'old'"
                *ngFor="let pools of getLimitedTransactionPoolInfo$ | async">
                <td class="timestamp">
                    <span *ngIf="pools.timestamp > 0">{{
                        pools.timestamp * 1000 | date: 'YYYY-MM-dd HH:mm:ss':'+0000'
                    }}</span>
                    <span *ngIf="pools.timestamp === 0">0</span>
                </td>
                <td class="age">{{ pools.timestamp * 1000 | amTimeAgo }}</td>
                <td class="size">{{ pools.blob_size }} bytes</td>
                <td class="fee">{{ pools.fee | moneyParse }}</td>
                <td class="hash">
                    <span class="cont_hash">
                        <a [routerLink]="['/transaction', pools.id]">{{
                            pools.id
                        }}</a>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>

    <div
        class="short_info_pool"
        *ngIf="
            poolsOn === false &&
            transactionCount > 0
        ">
        {{ transactionCount }} transactions in pool
    </div>

    <div
        class="pool_is_empty"
        *ngIf="transactionCount === 0">
        Pool is empty
    </div>

    <div
        class="pagination trans_pool"
        *ngIf="poolsOn === true && transactionCount >= 5">
        <div class="left_position"></div>
        <div class="right_position">
            <span>Items on page:</span>
            <input
                type="text"
                [value]="setPoolLimit"
                (input)="setPoolLimit = $event.target.value"
                [(ngModel)]="setPoolLimit"
                (change)="onChangePoolLimit()" />
            <span class="of">of {{ transactionCount }}</span>
        </div>
    </div>
</div>